<template>
    <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">

        <div class="title-group">
            <h4>Notifications</h4>
            <a href="#" @click.prevent="clearAll()">Clear all notifications</a>
            <a href="#" @click.prevent="readAll()">Mark all as read</a>
        </div>

        <Alerts :_error="error" :_success="success"></Alerts>

        <div class="notifications-list">

            <div v-if="wait">
                <p>Loading your notifications, Please wait...</p>
            </div>

            <div v-if="!wait && notifications.data.length == 0" class="posts-empty">
                <div class="posts-empty-inner">
                    <div class="posts-empty-inner_img">
                        <img loading="lazy" src="@/assets/img/read-messages.png" width="80" height="80"
                             alt="Empty box"/>
                    </div>
                    <h4>No new notifications.</h4>
                    <h6>We will send you notifications when there is new activity.</h6>
                </div>
            </div>

            <ul>
                <li v-for="i in notifications.data" :key="i.id" :class="!isRead(i) ? 'unread': 'read'">
                    <div class="notification-info">
                        <div class="notification-message">
                            <p class="mb-0 " style="font-size: 13px; font-weight: normal;" v-html="i.data.message"></p>
                            <span class="date-stamp text-muted">{{ moment(i.created_at).format('MMM, DD H:mm') }}</span>
                        </div>
                    </div>
                    <div class="notification-actions">
                        <!-- Dropdown menu -->
                        <b-dropdown right no-caret variant="link" :id="`dropdown-${i.id}`"
                                    toggle-class="text-decoration-none"
                                    class="survey-list-item-options">
                            <template #button-content>
                                <i class="fas fa-ellipsis-h"></i>
                            </template>
                            <b-dropdown-item>
                                <a href="#" v-if="!isRead(i)" @click.prevent="markAsRead(i)"
                                   :disabled="loading && selected_id === i.id">
                                    Mark as Read
                                </a>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <a href="#" @click="clearOne(i)">
                                    Clear
                                </a>
                            </b-dropdown-item>
                        </b-dropdown>

                        <!--                        <button v-if="!isRead(i)" @click.prevent="markAsRead(i)" class="custom-btn custom-btn-primary"-->
                        <!--                                :disabled="loading && selected_id === i.id">-->
                        <!--                            <template v-if="loading && selected_id === i.id">Wait...</template>-->
                        <!--                            <template v-else>Mark as read</template>-->
                        <!--                        </button>-->
                        <!--                        <small v-else class="text-muted">Marked as read on {{ moment(i.read_at).format('MMM, DD H:mm')}}</small>-->
                    </div>
                </li>
            </ul>

        </div>
        <pagination :limit="1" :data="notifications" @pagination-change-page="loadNotifications"></pagination>

    </div>
</template>

<script>

import Alerts from "@/components/Views/Helpers/Alerts";

export default {
    name: "Notifications",
    components: {Alerts},
    props: ['auth'],
    data() {
        return {
            selected_id: '',
            notifications: {data: []},
            show: false
        }
    },
    mounted() {
        this.loadNotifications()

        window.Echo.private(`user.` + this.auth.id)
            .listen('.user.notify', () => {
                this.loadNotifications()
            });
    },
    methods: {
        isRead(i) {
            return (i.read_at != null)
        },
        clearAll() {
            this.setLoading()
            this.clear()
            this.$http.post('/api/notifications/clear').then(() => {
                this.success = 'Notifications cleared successfully'
                this.$store.dispatch('loadNotificationsCount')
            }).catch(error => {
                this.showError(error)
            }).then(() => {
                this.wait = false
            })
        },
        clearOne(msg) {
            this.$http.post('/api/notifications/clear', {id: msg.id}).then(() => {
                this.notifications.data.splice(msg.id, 1);
                this.$store.dispatch('loadNotificationsCount')
            }).catch(error => {
                this.showError(error)
            })
        },
        readAll() {
            this.$http.post('/api/notifications/read').then(() => {
                this.$store.dispatch('loadNotificationsCount')
                this.loadNotifications()
            }).catch(error => {
                this.showError(error)
            })
        },
        markAsRead(i) {
            this.clear()
            this.selected_id = i.id
            this.setLoading()
            this.$http.post('/api/notifications/read', {id: i.id}).then(() => {
                let index = this.notifications.data.findIndex(a => a.id === i.id)
                this.notifications.data[index].read_at = new Date()
                this.$store.dispatch('loadNotificationsCount')
            }).catch(error => {
                this.showError(error)
            }).then(() => {
                this.wait = false
            })
        },
        loadNotifications(page = 1) {
            window.scroll(0, 0)
            this.loading = true
            this.clear()
            this.$http.get('/api/notifications?page=' + page).then(res => {
                this.notifications = res.data.data
                this.$store.dispatch('loadNotificationsCount')

            }).catch(err => {
                this.showError(err)
            }).then(() => {
                this.loading = false
            })
        }
    },
}
</script>

<style lang="scss" scoped>
// Sass partial for styling the notifications page

.title-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;

    h4 {
        margin-bottom: 0;
    }

    a {
        color: #0B2559;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // @include convert-to-rem( 13, 600 );
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }
    }
}

.notifications-list {
    ul {
        // @extend %unstyled-list;
        li {
            //background-color: #fff;
            border-bottom: 1px solid lightgrey;
            display: flex;
            justify-content: space-between;
            margin-bottom: 2px;
            padding: 20px;
            position: relative;

            .notification-info {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: 80%;

                &::before {
                    color: #E4A400;
                    content: "\f111";
                    display: flex;
                    float: left;
                    font: {
                        family: "Font Awesome 5 Free";
                        size: 10px;
                        weight: 900;
                    }
                    height: 100%;
                    margin-right: 14px;
                }
            }

            &.unread {
                //background-color: lightgray;
                font-weight: 600;
            }

            &.read {
                .notification-info {
                    &::before {
                        color: #299F00;
                    }
                }
            }

            span {
                &.date-stamp {
                    font-size: 12px;
                    // @include convert-to-rem( 13 );
                }
            }
        }
    }
}

@media(max-width: 575.98px) {
    .notifications-list {
        ul {
            li {
                display: block;

                .notification-info {
                    margin-bottom: 15px;

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.6s;
}

.slide-fade-enter,
.slide-fade-leave-active {
    opacity: 0;
}

.slide-fade-enter {
    transform: translateX(31px);
}

.slide-fade-leave-active {
    transform: translateX(-31px);
}

// Dropdown Menu Animation

.dropdown-enter-active,
.dropdown-leave-active {
    transition: all 1s;
}

.dropdown-enter,
.dropdown-leave-to {
    opacity: 0;
    transform: translateY(30px);
}

// Wrapper

.main {
    margin: 6rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__subtitle {
        color: #4f5959;
        font-weight: 100;
        margin-bottom: 2rem;
    }

    &__link {
        margin-right: 1.5rem;
        display: inline-block;

        &:last-child {
            margin-right: 0;
        }
    }
}

</style>
